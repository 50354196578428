<template>
  <div>
    <v-sheet class="pa-4">
      <h4 class="title">Информация о проекте</h4>
    </v-sheet>
    <v-card flat>
      <v-card-text>
        <LogotypeUpdateComponent class="mb-5 d-block" :project="project" />

        <v-text-field
          v-model="project.customer"
          :readonly="readOnly"
          autocomplete="off"
          label="Заказчик"
          outlined
          placeholder="Сургутский государственный университет"
        />
        <v-autocomplete
          v-model="project.project_type_id"
          :items="$store.state.projects.project_types"
          :readonly="readOnly"
          auto-select-first
          autocomplete="off"
          item-text="name"
          item-value="id"
          label="Тип проекта"
          outlined
        />
        <label>Цель проекта</label>

        <v-textarea v-model="project.participation_purpose" :readonly="readOnly" outlined />
        <v-text-field v-model="project.title" :readonly="readOnly" label="Название проекта" outlined />
        <v-textarea v-model="project.task" :readonly="readOnly" label="Задача" outlined />
        <v-textarea v-model="project.problem" :readonly="readOnly" label="Проблема, которую решает проект" outlined />
        <v-textarea v-model="project.result" :readonly="readOnly" label="Планируемый результат" outlined />
        <v-textarea v-model="project.resources" :readonly="readOnly" label="Требуемые материальные ресурсы ресурсы" outlined />

        <v-combobox
          :readonly="readOnly"
          dense
          filled
          label="Тип продукта"
          outlined
          :items="['изделие', 'приложение', 'мероприятие', 'модель', 'медиа ресурс']"
          v-model="project.product_type"
        />
        <v-btn outlined rounded v-if="!readOnly" :loading="saveLoading" color="blue darken-2" dark @click="save">Сохранить</v-btn>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import LogotypeUpdateComponent from '@/components/Projects/ProjectsItem/LogotypeUpdateComponent'

export default {
  name: 'ProjectsItemInfoComponent',
  components: { LogotypeUpdateComponent },
  computed: {
    project() {
      return this.$store.state.projects.currentProject
    },

    readOnly() {
      return !this.$store.state.projects.currentProject.permissions.can_edit_info
    },
  },
  data() {
    return {
      saveLoading: false,
    }
  },
  methods: {
    save() {
      this.saveLoading = true
      this.$store.dispatch('updateProject', this.$store.state.projects.currentProject).then(() => {
        this.saveLoading = false
      })
    },
  },
  mounted() {},
}
</script>

<style scoped></style>
